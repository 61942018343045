import React, { useState } from 'react';

import Logo from '../../../images/logo-that-mexican-place-old.png';
import { Link, navigate } from 'gatsby';
import LocationModal from '../../LocationModalHeadlessUI';

const S1HomeHero = ({ bgImage, Title, Description, onSelect }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const locations = [
    {
      name: 'Escondido Location',
      address: '200 W El Norte Pkwy, Escondido, CA 92026',
      path: '/escondido',
      onSelect: 'escondido'
    },
    {
      name: 'Hemet Location',
      address: '281 N Sanderson Ave Ste 281, Hemet, CA 92545',
      path: '/hemet',
      onSelect: 'hemet'
    }
  ];

  return (
    <>
      <section
        className="text-gray-600 body-font heroBG"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(47, 133, 90, 0.65) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 66%, rgba(213, 0, 0, 0.65)), linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), url(${bgImage})`,
        }}
      >
        <div className="container flex flex-col items-center justify-center px-5 py-24 mx-auto lg:pb-8 animate-fade-in-fwd">
          {/* Start - Side by side choice for users to choose the correct restaurant location */}
          <div className='hidden lg:flex lg:flex-row w-full justify-evenly'>
          {locations.map((location, index) => (
      <Link
        key={index}
        className="w-1/2 mb-5 lg:w-1/4 5xl:w-1/3 hover:bg-black hover:bg-opacity-50 focus:bg-black focus:opacity-50"
        to={location.path}
        onClick={() => {
          onSelect(location.onSelect);
          navigate(location.path);
        }}
      >
        <img
          className="object-cover object-center w-3/4 mx-auto rounded"
          alt="hero"
          src={Logo}
        />
        <div className="mx-auto text-center text-white fontFredoka">
          <span className="text-xl 5xl:text-5xl text-center fontFredoka" style={{ textShadow: "2px 2px 4px black" }}>{location.name}</span>
          <span className="block text-lg 5xl:text-4xl text-center" style={{ textShadow: "2px 2px 4px black" }}>{location.address}</span>
        </div>
        <div className="flex pt-4 justify-center">
          <button
            className="w-full px-10 py-5 text-xl 5xl:text-3xl text-center text-white bg-red-600 rounded-md cursor-pointer hover:bg-red-800 fontFredoka"
          >
            {' '}Select Location
          </button>
        </div>
      </Link>
          ))}
           {/* <Link className="w-1/2 mb-5 lg:w-1/4 5xl:w-1/3 hover:bg-black hover:bg-opacity-50 focus:bg-black focus:opacity-50" to="/escondido"
              onClick={() => {
                onSelect('escondido');
                navigate('/escondido');
              }}
            >
              <img
                className="object-cover object-center w-3/4 mx-auto rounded"
                alt="hero"
                src={Logo}
              />
              <div className="mx-auto text-center text-white fontFredoka">
                <span className="text-xl text-center fontFredoka" style={{ textShadow: "2px 2px 4px black" }}>Escondido Location</span>
                <span className="block text-lg text-center" style={{ textShadow: "2px 2px 4px black" }} >200 W El Norte Pkwy, Escondido, CA 92026</span>
              </div>


              <div className="flex pt-4 justify-center">

                <button
                  className="w-full px-10 py-5 text-xl text-center text-white bg-red-600 rounded-md cursor-pointer hover:bg-red-800 fontFredoka"
                >
                  {' '}Select Location
                </button>
              </div>


            </Link>

            <Link className="w-1/2 mb-5 lg:w-1/4 5xl:w-1/3 hover:bg-black hover:bg-opacity-50 focus:bg-black focus:opacity-50" to="/hemet"
              onClick={() => {
                onSelect('hemet');
                navigate('/hemet');
              }}
            >
              <img
                className="object-cover object-center w-3/4 mx-auto rounded"
                alt="hero"
                src={Logo}
              />
              <div className="mx-auto text-center text-white fontFredoka">
                <span className="text-xl text-center fontFredoka" style={{ textShadow: "2px 2px 4px black" }}>Hemet Location</span>
                <span className="block text-lg text-center" style={{ textShadow: "2px 2px 4px black" }} >281 N Sanderson Ave Ste 281, Hemet, CA 92545</span>
              </div>

              <div className="flex pt-4 justify-center">

                <button
                  className="w-full px-10 py-5 text-xl text-center text-white bg-red-600 rounded-md cursor-pointer hover:bg-red-800 fontFredoka"
                >
                  {' '}Select Location
                </button>
              </div>


            </Link>*/}
          </div>
          {/* End Desktop; begin Mobile */}
          <div className='lg:hidden flex flex-col w-full justify-center'>

            <img
              className="object-cover object-center w-1/2 mx-auto rounded"
              alt="hero"
              src={Logo}
            />
            {/*<Link className=" hover:bg-black hover:bg-opacity-50 focus:bg-black focus:opacity-50" to="/escondido"
              onClick={() => {
                onSelect('escondido');
                navigate('/escondido');
              }}
            >
              <div className="mx-auto text-center text-white fontFredoka">
                <span className="text-xl text-center fontFredoka" style={{ textShadow: "2px 2px 4px black" }}>Escondido Location</span>
                <span className="block text-lg text-center" style={{ textShadow: "2px 2px 4px black" }} >200 W El Norte Pkwy, Escondido, CA 92026</span>
              </div>
            </Link>

            <Link className=" hover:bg-black hover:bg-opacity-50 focus:bg-black focus:opacity-50" to="/hemet"
              onClick={() => {
                onSelect('hemet');
                navigate('/hemet');
              }}
            >
              <div className="mx-auto text-center text-white fontFredoka">
                <span className="text-xl text-center fontFredoka" style={{ textShadow: "2px 2px 4px black" }}>Hemet Location</span>
                <span className="block text-lg text-center" style={{ textShadow: "2px 2px 4px black" }} >281 N Sanderson Ave Ste 281, Hemet, CA 92545</span>
              </div>
            </Link>*/}
          </div>
          {/* End Mobile */}
          {/* End - Side by side choice for users to choose the correct restaurant location */}

          <div className="w-full text-center lg:w-2/3">
            <h1 className="invisible text-xs font-medium">
              {Title}
            </h1>
            <p
              className="mt-6 5xl:mt-24 mb-8 text-xl 5xl:text-5xl font-bold leading-relaxed text-white lg:text-2xl"
              style={{ textShadow: '2px 2px 4px black' }}
            >
              {Description}
            </p>
            <div className="flex lg:hidden justify-center">

              <button
                className="w-full px-10 py-5 text-xl text-center text-white bg-red-600 rounded-md cursor-pointer hover:bg-red-800 fontFredoka"
                onClick={handleOpenModal}
              >
                {' '}
                See Menu &amp; Order
              </button>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="svg-pattern-1"
            viewBox="0 0 283.5 25.8"
            preserveAspectRatio="none"
            fill="#fff"
          >
            <path d="M283.5,9.7c0,0-7.3,4.3-14,4.6c-6.8,0.3-12.6,0-20.9-1.5c-11.3-2-33.1-10.1-44.7-5.7    s-12.1,4.6-18,7.4c-6.6,3.2-20,9.6-36.6,9.3C131.6,23.5,99.5,7.2,86.3,8c-1.4,0.1-6.6,0.8-10.5,2c-3.8,1.2-9.4,3.8-17,4.7   c-3.2,0.4-8.3,1.1-14.2,0.9c-1.5-0.1-6.3-0.4-12-1.6c-5.7-1.2-11-3.1-15.8-3.7C6.5,9.2,0,10.8,0,10.8V0h283.5V9.7z M260.8,11.3  c-0.7-1-2-0.4-4.3-0.4c-2.3,0-6.1-1.2-5.8-1.1c0.3,0.1,3.1,1.5,6,1.9C259.7,12.2,261.4,12.3,260.8,11.3z M242.4,8.6 c0,0-2.4-0.2-5.6-0.9c-3.2-0.8-10.3-2.8-15.1-3.5c-8.2-1.1-15.8,0-15.1,0.1c0.8,0.1,9.6-0.6,17.6,1.1c3.3,0.7,9.3,2.2,12.4,2.7  C239.9,8.7,242.4,8.6,242.4,8.6z M185.2,8.5c1.7-0.7-13.3,4.7-18.5,6.1c-2.1,0.6-6.2,1.6-10,2c-3.9,0.4-8.9,0.4-8.8,0.5 c0,0.2,5.8,0.8,11.2,0c5.4-0.8,5.2-1.1,7.6-1.6C170.5,14.7,183.5,9.2,185.2,8.5z M199.1,6.9c0.2,0-0.8-0.4-4.8,1.1  c-4,1.5-6.7,3.5-6.9,3.7c-0.2,0.1,3.5-1.8,6.6-3C197,7.5,199,6.9,199.1,6.9z M283,6c-0.1,0.1-1.9,1.1-4.8,2.5s-6.9,2.8-6.7,2.7  c0.2,0,3.5-0.6,7.4-2.5C282.8,6.8,283.1,5.9,283,6z M31.3,11.6c0.1-0.2-1.9-0.2-4.5-1.2s-5.4-1.6-7.8-2C15,7.6,7.3,8.5,7.7,8.6  C8,8.7,15.9,8.3,20.2,9.3c2.2,0.5,2.4,0.5,5.7,1.6S31.2,11.9,31.3,11.6z M73,9.2c0.4-0.1,3.5-1.6,8.4-2.6c4.9-1.1,8.9-0.5,8.9-0.8   c0-0.3-1-0.9-6.2-0.3S72.6,9.3,73,9.2z M71.6,6.7C71.8,6.8,75,5.4,77.3,5c2.3-0.3,1.9-0.5,1.9-0.6c0-0.1-1.1-0.2-2.7,0.2    C74.8,5.1,71.4,6.6,71.6,6.7z M93.6,4.4c0.1,0.2,3.5,0.8,5.6,1.8c2.1,1,1.8,0.6,1.9,0.5c0.1-0.1-0.8-0.8-2.4-1.3    C97.1,4.8,93.5,4.2,93.6,4.4z M65.4,11.1c-0.1,0.3,0.3,0.5,1.9-0.2s2.6-1.3,2.2-1.2s-0.9,0.4-2.5,0.8C65.3,10.9,65.5,10.8,65.4,11.1 z M34.5,12.4c-0.2,0,2.1,0.8,3.3,0.9c1.2,0.1,2,0.1,2-0.2c0-0.3-0.1-0.5-1.6-0.4C36.6,12.8,34.7,12.4,34.5,12.4z M152.2,21.1    c-0.1,0.1-2.4-0.3-7.5-0.3c-5,0-13.6-2.4-17.2-3.5c-3.6-1.1,10,3.9,16.5,4.1C150.5,21.6,152.3,21,152.2,21.1z" />
            <path d="M269.6,18c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3  C267.7,18.8,269.7,18,269.6,18z" />
            <path d="M227.4,9.8c-0.2-0.1-4.5-1-9.5-1.2c-5-0.2-12.7,0.6-12.3,0.5c0.3-0.1,5.9-1.8,13.3-1.2   S227.6,9.9,227.4,9.8z" />
            <path d="M204.5,13.4c-0.1-0.1,2-1,3.2-1.1c1.2-0.1,2,0,2,0.3c0,0.3-0.1,0.5-1.6,0.4   C206.4,12.9,204.6,13.5,204.5,13.4z" />
            <path d="M201,10.6c0-0.1-4.4,1.2-6.3,2.2c-1.9,0.9-6.2,3.1-6.1,3.1c0.1,0.1,4.2-1.6,6.3-2.6  S201,10.7,201,10.6z" />
            <path d="M154.5,26.7c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3  C152.6,27.5,154.6,26.8,154.5,26.7z" />
            <path d="M41.9,19.3c0,0,1.2-0.3,2.9-0.1c1.7,0.2,5.8,0.9,8.2,0.7c4.2-0.4,7.4-2.7,7-2.6  c-0.4,0-4.3,2.2-8.6,1.9c-1.8-0.1-5.1-0.5-6.7-0.4S41.9,19.3,41.9,19.3z" />
            <path d="M75.5,12.6c0.2,0.1,2-0.8,4.3-1.1c2.3-0.2,2.1-0.3,2.1-0.5c0-0.1-1.8-0.4-3.4,0   C76.9,11.5,75.3,12.5,75.5,12.6z" />
            <path d="M15.6,13.2c0-0.1,4.3,0,6.7,0.5c2.4,0.5,5,1.9,5,2c0,0.1-2.7-0.8-5.1-1.4   C19.9,13.7,15.7,13.3,15.6,13.2z" />
          </svg>
        </div>
      </section>
      <LocationModal isOpen={isModalOpen} onClose={handleCloseModal} onSelect={onSelect} />

    </>
  );
};

export default S1HomeHero;
